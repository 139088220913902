import apiClient from 'services/axios'

// eslint-disable-next-line import/prefer-default-export
export async function get(jobId) {
  return apiClient
    .get(`/jobs/${jobId}/analytics`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
