import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import jobs from './jobs/sagas'
import occupations from './occupations/sagas'
import industries from './industries/sagas'
import educations from './educations/sagas'
import careers from './careers/sagas'
import languages from './languages/sagas'
import employmentTypes from './employmentTypes/sagas'
import legalForms from './legalForms/sagas'
import countries from './countries/sagas'
import company from './company/sagas'
import profile from './profile/sagas'
import jobStates from './jobStates/sagas'
import civilities from './civilities/sagas'
import availablities from './availablities/sagas'
import geographicMobilities from './geographicMobilities/sagas'
import applicantStatuses from './applicantStatuses/sagas'
import salaryRanges from './salaryRanges/sagas'
import studyTypes from './studyTypes/sagas'
import applications from './applications/sagas'
import applicationStage from './applicationStage/sagas'
import notes from './notes/sagas'
import emails from './emails/sagas'
import analytics from './analytics/sagas'
import attachments from './attachments/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    jobs(),
    occupations(),
    industries(),
    company(),
    educations(),
    careers(),
    languages(),
    employmentTypes(),
    legalForms(),
    profile(),
    countries(),
    jobStates(),
    civilities(),
    availablities(),
    geographicMobilities(),
    applicantStatuses(),
    salaryRanges(),
    studyTypes(),
    applications(),
    applicationStage(),
    notes(),
    emails(),
    analytics(),
    attachments(),
  ])
}
