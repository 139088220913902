/* eslint-disable camelcase */
import { notification } from 'antd'
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import * as applications from 'services/applications'
import actions from './actions'

const getIndustries = state => {
  return state.industries.list
}

const getEmploymentTypes = state => {
  return state.employmentTypes.list
}

const getAvailablities = state => {
  return state.availablities.list
}

const getGeographicMobilities = state => {
  return state.geographicMobilities.list
}

const getApplicantStatuses = state => {
  return state.applicantStatuses.list
}

const getSalaryRanges = state => {
  return state.salaryRanges.list
}

const getStudyTypes = state => {
  return state.studyTypes.list
}

export function* GET_ALL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const {
    pagination = { current: 1, pageSize: 9999 },
    job,
    applicationStage,
    industry,
    employmentType,
    geographicMobility,
  } = payload
  const { current, pageSize } = pagination
  const limit = pageSize
  const offset = (current - 1) * pageSize

  const response = yield call(
    applications.getAll,
    limit,
    offset,
    job,
    applicationStage,
    industry,
    employmentType,
    geographicMobility,
  )

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response.list,
        total: parseInt(response.total, 10),
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET({ payload }) {
  const { id } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      single: null,
    },
  })

  const response = yield call(applications.get, id)

  const industries = yield select(getIndustries)
  const employmentTypes = yield select(getEmploymentTypes)
  const availablities = yield select(getAvailablities)
  const geographicMobilities = yield select(getGeographicMobilities)
  const applicantStatuses = yield select(getApplicantStatuses)
  const salaryRanges = yield select(getSalaryRanges)
  const studyTypes = yield select(getStudyTypes)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        single: {
          ...response,
          employment: {
            ...response.employment,
            availablity: availablities?.find(
              item => item.value === response.employment?.availablity,
            ),
            desired_salary: salaryRanges?.find(
              item => item.id === response.employment?.desired_salary,
            ),
            employment_type: employmentTypes?.find(
              item => item.value === response.employment?.employment_type,
            ),
            industry: industries?.find(item => item.id === response.employment?.industry),
            geographic_mobility: geographicMobilities?.find(
              item => item.value === response.employment?.geographic_mobility,
            ),
            status: applicantStatuses?.find(item => item.value === response.employment?.status),
          },
          resume: {
            ...response.resume,
            educations: response.resume.educations?.map(education => ({
              ...education,
              study_type: studyTypes?.find(study => study.id === education.study_type),
            })),
          },
        },
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const {
    id,
    email,
    phoneNumber,
    stage,
    availablity,
    desiredSalary,
    employmentType,
    employmentStatus,
    geographicMobility,
    industry,
  } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(
    applications.update,
    id,
    email,
    phoneNumber,
    stage,
    availablity,
    desiredSalary,
    employmentType,
    employmentStatus,
    geographicMobility,
    industry,
  )

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Candidature modifiée',
      // description: "L'offre d'emploi a été activée avec succès",
    })

    yield put({
      type: actions.GET,
      payload: {
        id,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_STAGE({ payload }) {
  const { application, stage } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(
    applications.update,
    application.id,
    application.email,
    application.phone_number,
    stage,
  )

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Statut changé',
      // description: "L'offre d'emploi a été activée avec succès",
    })

    yield put({
      type: actions.GET,
      payload: {
        id: application.id,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL, GET_ALL),
    takeEvery(actions.GET, GET),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.UPDATE_STAGE, UPDATE_STAGE),
  ])
}
