import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as analytics from 'services/analytics'
import actions from './actions'

export function* GET({ payload }) {
  const { jobId } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const response = yield call(analytics.get, jobId)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET, GET)])
}
