import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as attachments from 'services/attachments'
import actions from './actions'

export function* GET_ALL({ payload }) {
  const { applicationId } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const response = yield call(attachments.getAll, applicationId)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ALL_BY_RESUME({ payload }) {
  const { resumeId } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      resume: null,
    },
  })

  const response = yield call(attachments.getAllByResume, resumeId)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        resume: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { applicationId, attachmentId } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(attachments.remove, applicationId, attachmentId)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Fichier supprimé',
      // description: 'Expérience suprimée avec succès',
    })

    yield put({
      type: actions.GET_ALL,
      payload: { applicationId },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL, GET_ALL),
    takeEvery(actions.GET_ALL_BY_RESUME, GET_ALL_BY_RESUME),
    takeEvery(actions.DELETE, DELETE),
  ])
}
