import apiClient from 'services/axios'

export async function getAll(applicationId) {
  return apiClient
    .get(`/applications/${applicationId}/mails`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function send(applicationId, data) {
  return apiClient
    .post(`/applications/${applicationId}/notify`, data)
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
