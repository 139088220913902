import apiClient from 'services/axios'

// eslint-disable-next-line import/prefer-default-export
export async function getAll(
  limit,
  offset,
  job,
  applicationStage,
  industry,
  employmentType,
  geographicMobility,
) {
  let url = `/applications?limit=${limit}&offset=${offset}`
  url += job ? `&conditions=applications.job_id,eq,${job}` : ''
  url += applicationStage ? `&conditions=applications.stage,eq,${applicationStage}` : ''
  url += industry ? `&conditions=applications.industry,eq,${industry}` : ''
  url += employmentType ? `&conditions=applications.employment_type,eq,${employmentType}` : ''
  url += geographicMobility
    ? `&conditions=applications.geographic_mobility,eq,${geographicMobility}`
    : ''

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return {
          list: response.data,
          total: response.headers['content-range']?.split('/')[1],
        }
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function get(id) {
  return apiClient
    .get(`/applications/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function update(
  id,
  email,
  phoneNumber,
  stage,
  availablity = null,
  desiredSalary = null,
  employmentType = null,
  employmentStatus = null,
  geographicMobility = null,
  industry = null,
) {
  return apiClient
    .put(`/applications/${id}`, {
      email,
      phone_number: phoneNumber,
      stage,
      availablity: availablity ?? undefined,
      desired_salary: desiredSalary ?? undefined,
      employment_type: employmentType ?? undefined,
      employment_status: employmentStatus ?? undefined,
      geographic_mobility: geographicMobility ?? undefined,
      industry: industry ?? undefined,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
