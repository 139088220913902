import actions from './actions'

const initialState = {
  id: '',
  name: '',
  loading: false,
  owner: '',
  state: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...action.payload }
    default:
      return state
  }
}
