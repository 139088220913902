import apiClient from 'services/axios'

export async function getAll(applicationId) {
  return apiClient
    .get(`/applications/${applicationId}/notes`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function add(applicationId, data) {
  return apiClient
    .post(`/applications/${applicationId}/notes`, data)
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function update(applicationId, noteId, data) {
  return apiClient
    .put(`/applications/${applicationId}/notes/${noteId}`, data)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function remove(applicationId, noteId) {
  return apiClient
    .delete(`/applications/${applicationId}/notes/${noteId}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
