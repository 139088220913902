import apiClient from 'services/axios'
// import store from 'store'

export async function get() {
  return apiClient
    .get(`/company`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function update(data) {
  return apiClient
    .put(`/company`, data)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
