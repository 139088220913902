const actions = {
  SET_STATE: 'jobs/SET_STATE',
  GET_ALL: 'jobs/GET_ALL',
  GET: 'jobs/GET',
  ADD: 'jobs/ADD',
  UPDATE: 'jobs/UPDATE',
  DELETE: 'jobs/DELETE',
  DISABLE: 'jobs/DISABLE',
  ENABLE: 'jobs/ENABLE',
}

export default actions
