import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import * as company from 'services/company'
import actions from './actions'

const getIndustry = (state, id) => {
  return state.industries.list.find(item => item.id === id)
}

export function* GET() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(company.get)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        ...response,
        industry: yield select(getIndustry, response.industry?.id),
        loading: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(company.update, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Votre entreprise a été modifiée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET, GET), takeEvery(actions.UPDATE, UPDATE)])
}
