import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import jobs from './jobs/reducers'
import occupations from './occupations/reducers'
import industries from './industries/reducers'
import educations from './educations/reducers'
import careers from './careers/reducers'
import languages from './languages/reducers'
import employmentTypes from './employmentTypes/reducers'
import legalForms from './legalForms/reducers'
import countries from './countries/reducers'
import company from './company/reducers'
import profile from './profile/reducers'
import jobStates from './jobStates/reducers'
import civilities from './civilities/reducers'
import availablities from './availablities/reducers'
import geographicMobilities from './geographicMobilities/reducers'
import applicantStatuses from './applicantStatuses/reducers'
import salaryRanges from './salaryRanges/reducers'
import studyTypes from './studyTypes/reducers'
import applications from './applications/reducers'
import applicationStage from './applicationStage/reducers'
import notes from './notes/reducers'
import emails from './emails/reducers'
import analytics from './analytics/reducers'
import attachments from './attachments/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    jobs,
    occupations,
    industries,
    company,
    educations,
    careers,
    languages,
    employmentTypes,
    legalForms,
    profile,
    countries,
    jobStates,
    civilities,
    availablities,
    geographicMobilities,
    applicantStatuses,
    salaryRanges,
    studyTypes,
    applications,
    applicationStage,
    notes,
    emails,
    analytics,
    attachments,
  })
