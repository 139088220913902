import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(jwt.login, email, password)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/offres')
    notification.success({
      message: 'Connecté',
      description: 'Vous êtes connectés à votre compte',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })

    notification.error({
      message: 'Informations erronnées',
      description: 'Veuillez verifier vos informations',
    })
  }
}

export function* REGISTER({ payload }) {
  const { values } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(jwt.register, values)
  if (response === true) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield history.push('/auth/login')
    notification.success({
      message: 'Enregistré',
      description: 'Vous avez créé votre compte',
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        errors: response.errors,
      },
    })

    notification.error({
      message: 'Erreur',
      description: "Probléme lors de l'inscription",
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(jwt.currentAccount)
  if (response) {
    const {
      id,
      email,
      first_name: firstName,
      last_name: lastName,
      roles,
      occupation,
      company,
    } = response

    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        firstName,
        lastName,
        email,
        roles,
        authorized: true,
        occupation,
        company,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(jwt.logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      roles: '',
      authorized: false,
      loading: false,
    },
  })
}

export function* FORGOT_PASSWORD({ payload }) {
  const { email } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(jwt.forgot, { email })
  if (success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Demande de réinitialisation envoyé',
      description: 'Veuillez consulter votre boite mail',
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* VERIFY_TOKEN({ payload }) {
  const { email, token } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(jwt.verifyToken, email, token)
  if (success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Email ou token invalides',
      description: 'Veuillez réessayer avec un lien valid',
    })
    yield history.push('/auth/login')
  }
}

export function* RESET_PASSWORD({ payload }) {
  const { token, password, confirm_password: confirmPassword } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(jwt.reset, token, password, confirmPassword)
  if (success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Mot de passe réinitialisé',
      description: 'Connectez vous avec votre nouveau mot de passe',
    })
    yield history.push('/auth/login')
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* COMPLETE_REGISTRATION({ payload }) {
  const { email, token } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(jwt.completeRegistration, email, token)
  if (success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Email ou token invalides',
      description: 'Veuillez réessayer avec un lien valid',
    })
  }
  yield history.push('/auth/login')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.VERIFY_TOKEN, VERIFY_TOKEN),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.COMPLETE_REGISTRATION, COMPLETE_REGISTRATION),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
