import apiClient from 'services/axios'

export async function getAll(applicationId) {
  return apiClient
    .get(`/applications/${applicationId}/attachments`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAllByResume(resumeId) {
  return apiClient
    .get(`/resumes/${resumeId}/attachments`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function remove(applicationId, attachmentId) {
  return apiClient
    .delete(`/applications/${applicationId}/attachments/${attachmentId}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
