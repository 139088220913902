import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as notes from 'services/notes'
import actions from './actions'

export function* GET_ALL({ payload }) {
  const { applicationId } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const response = yield call(notes.getAll, applicationId)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* ADD({ payload }) {
  const { applicationId, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(notes.add, applicationId, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Commentaire ajouté',
      // description: 'Expérience ajoutée avec succès',
    })

    yield put({
      type: actions.GET_ALL,
      payload: { applicationId },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { applicationId, noteId, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(notes.update, applicationId, noteId, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Commentaire modifié',
      // description: 'Expérience modifiée avec succès',
    })

    yield put({
      type: actions.GET_ALL,
      payload: { applicationId },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { applicationId, noteId } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(notes.remove, applicationId, noteId)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Commentaire supprimé',
      // description: 'Expérience suprimée avec succès',
    })

    yield put({
      type: actions.GET_ALL,
      payload: { applicationId },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL, GET_ALL),
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
  ])
}
