import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as emails from 'services/emails'
import actions from './actions'

export function* GET_ALL({ payload }) {
  const { applicationId } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const response = yield call(emails.getAll, applicationId)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* SEND({ payload }) {
  const { applicationId, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(emails.send, applicationId, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Email envoyé',
      // description: 'Expérience ajoutée avec succès',
    })

    yield put({
      type: actions.GET_ALL,
      payload: { applicationId },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALL, GET_ALL), takeEvery(actions.SEND, SEND)])
}
